import { render, staticRenderFns } from "./BaseDesc.vue?vue&type=template&id=2430d597&scoped=true"
import script from "./BaseDesc.vue?vue&type=script&lang=js"
export * from "./BaseDesc.vue?vue&type=script&lang=js"
import style0 from "./BaseDesc.vue?vue&type=style&index=0&id=2430d597&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2430d597",
  null
  
)

export default component.exports